<template>
  <div id="Tokenomic" class="buybox supernettFont"> 
    <div class="buybox_right">
      <h2 class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".1s">
        <img src="@/assets/image/bscm/coin.png" alt="">
        <span>Tokenomics</span> 
        <img src="@/assets/image/bscm/coin.png" alt="">
      </h2>
      <div>
        <h4 class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".2s">Token Allocation</h4>
        <img src="@/assets/image/bscm/u.png" alt="">
        <p class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".2.2s">
          <label for="">100%</label>
          <span> fair launch in Pump</span>
        </p>
        <!-- <p class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".2.2s">
          <label for="">70%</label>
          <span> Pancake liquidity</span>
        </p>
        <p class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".2.5s">
          <label for="">30%</label>
          <span> Free airdrop</span>
        </p> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted(){
    var options={
        //默认为true
        live:false
    }
    new this.$WOW.WOW(options).init();
  }
}
</script>

<style lang="scss" scoped>
.buybox{
  display: flex;
  justify-content: center;
  margin-top: 80px;
  position: relative;
  padding-bottom: 100px;
}
.buybox::before{
  content: '';
  position: absolute;
  width: 100%;
  height: 8px;
  bottom: 0px;
  background: url('../../assets/image/line.png') no-repeat center center/100%;
}
.buybox_right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2{
    color: #F8C10A;
    font-size: 36px; 
    text-transform: uppercase;
    margin: 14px 0px 32px;
    position: relative;
    span{
      margin: 0px 30px;
    }
    img{
      width: 57px;
    }
  }
  >div{
    text-align: center;
    h4{
      font-size: 35px;
      color: #FFFFFF;
      line-height: 42px;
      text-align: center;
      text-transform: uppercase;
    }
    img{
      width: 65px;
      margin: 8px 0 3px;
    }
    p{
      font-weight: normal;
      font-size: 35px;
      color: #FFFFFF;
      line-height: 42px;
      text-align: center;
      font-style: normal;
      label{
        color: #6AD0C7;
      }
    }
    p+p{
      margin-top: 30px;
    }
  }
}
@media screen and (max-width:768px) {
  .buybox{
    width: 100%;
    margin-top: 80px;
    padding-bottom: 30px;
    &::before{
      display: none;
    }
    

  }
  .buybox_right{
    h2{
      font-size: 30px;
      margin: 0px 0px 50px;
      span{
        margin: 0px 22px;
      }
      img{
        width: 42px;
      }
    }
    >div{
      h4{
        font-size: 22px;
        line-height: 27px;
      }
      img{
        width: 64px;
        margin: 8px 0 7px;
      }
      p{
        font-size: 22px;
        line-height: 27px;
      }
      p+p{
        margin-top: 20px;
      }
    }
  }
}
</style>